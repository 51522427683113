import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "fonts/RobotoCondensed-Regular.ttf";
import "fonts/RobotoCondensed-Bold.ttf";
import "fonts/RobotoCondensed-Light.ttf";
import "fonts/RobotoCondensed-Italic.ttf";
import { addAnalytics, analyticsSetup } from "helpers/analytics";
import * as Sentry from "@sentry/react";

if (localStorage.getItem("debug") === "83s,na7y3") {
  console.log("process.env", process.env);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

/**
 * Sentry error tracker
 *
 *  LOCAL & Development - configuration in `app/.env.local` if available, otherwise FALSE for development
 *  ACCEPTANCE - configuration in `~/.bash_profile`, default FALSE
 *  PRODUCTION - configuration in `~/.bash_profile`, default TRUE
 */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/.uspornebydleni\.csob.\cz\//],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: Number.parseFloat(
    process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || "0.1",
  ),
  environment: process.env.REACT_APP_ENV,
  debug: process.env.REACT_APP_SENTRY_DEBUG === "true",
  enabled:
    process.env.REACT_APP_SENTRY_ENABLED !== undefined
      ? process.env.REACT_APP_SENTRY_ENABLED === "true"
      : process.env.REACT_APP_ENV !== "development",
  replaysSessionSampleRate:
    process.env.REACT_APP_ENV === "production" ? 0.1 : 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production."
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
addAnalytics();
window.onload = analyticsSetup;
